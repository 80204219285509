const size = (px: number) => px / 16 + "rem";

const commonTextStyle = {
  marginTop: 0,
  marginBottom: 0,
  cursor: "inherit"
};

const light = "300";
const regular = "normal";
const medium = "600";
const bold = "700";

export const text = {
  size,
  font: {
    light,
    regular,
    medium,
    bold
  },
  h1: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(64),
    lineHeight: size(72),
    letterSpacing: -2
  },
  h2: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(48),
    lineHeight: size(56)
  },
  h3: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(40),
    lineHeight: size(48)
  },
  h4: {
    ...commonTextStyle,
    fontWeight: bold,
    fontSize: size(28),
    lineHeight: size(40)
  },
  h5: {
    ...commonTextStyle,
    fontWeight: bold,
    fontSize: size(24),
    lineHeight: size(32)
  },
  h6: {
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(20),
    lineHeight: size(24)
  },
  subtitle0: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(18),
    lineHeight: size(26)
  },
  subtitle1: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(16),
    lineHeight: size(24)
  },
  subtitle2: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(14),
    lineHeight: size(22)
  },
  body0: {
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(18),
    lineHeight: size(26)
  },
  body1: {
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(16),
    lineHeight: size(24)
  },
  body2: {
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(14),
    lineHeight: size(22)
  },
  button: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(16),
    lineHeight: 1.5
  },
  caption: {
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(12),
    lineHeight: 1.66
  },
  overline: {
    ...commonTextStyle,
    fontWeight: regular,
    fontSize: size(10),
    lineHeight: 2.66,
    textTransform: "uppercase"
  },
  // custom variants
  link: {
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit",
    ":hover": {
      opacity: 0.85
    }
  },
  strongCaption: {
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(12),
    lineHeight: 1.66
  },
  icon: {
    display: "inline-block",
    fontSize: "inherit",
    verticalAlign: "middle",
    marginLeft: 2,
    marginRight: 2
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
};
