import * as React from "react";
import HeaderImage from "../static/main-header-image.jpg";
import { Box } from "react-system";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MainHeader } from "./MainHeader";
import { OurMagic } from "./OurMagic";
import { Center } from "./Center";
import { text } from "../controls/text";

const useStyles = makeStyles(theme => ({
  textField: {
    "& .MuiOutlinedInput-input": {
      width: 300,
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.white
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.white
      },
      "&.Mui-focused fieldset": {
        borderColor: "green"
      }
    }
  },
  button: {
    display: "block",
    textTransform: "none",
    marginTop: "1em",
    width: 330,
    lineHeight: 2.3,
    fontSize: 16
  }
}));

export const App = () => {
  const classes = useStyles();
  return (
    <div>
      <MainHeader />
      <Box
        style={{
          background: `linear-gradient(rgba(0, 29, 56, 0.6),rgba(0, 29, 56, 0.8)), url(${HeaderImage})`,
          minHeight: "70vh",
          color: "white"
        }}
      >
        <Center pl={6} css={{ paddingTop: "6em" }}>
          <Box css={text.h1}>
            Now Anyone Can <br /> Invest In Real Estate
          </Box>
          <Box my={3} css={text.h6}>
            Invest as little as 2.5 Lakhs in budding real estate developments{" "}
            <br />
            and earn a return after the completion.
          </Box>
          <Box my={2} css={text.h5}>
            Get Started
          </Box>

          <TextField
            id="outlined-basic"
            placeholder="Enter your email"
            variant="outlined"
            className={classes.textField}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Become an investor
          </Button>
          <Box id="our-magic" my={2} css={text.caption}>
            Real estate investments are subject to market risks they <br />
            can result in losses.
          </Box>
        </Center>
      </Box>
      <OurMagic />
    </div>
  );
};
