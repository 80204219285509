import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import { Box } from "react-system";
import { text } from "../controls/text";
import {
  EmojiEmotions,
  Share,
  Explore,
  FilterList,
  AssignmentTurnedIn,
  AccountBalance,
  BusinessCenter,
  People
} from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import "react-vertical-timeline-component/style.min.css";

export const OurMagic = () => {
  return (
    <Box css={{ backgroundColor: "#e3e3e3" }}>
      <Box py={4} css={{ ...text.h2, textAlign: "center" }}>
        Our Magical Formula{" "}
        <span role="img" aria-label="rocket">
          🚀
        </span>
      </Box>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: blue[500], color: "#fff" }}
          contentArrowStyle={{ borderRight: `7px solid  ${blue[500]}` }}
          iconStyle={{ background: "#fff", color: blue[500] }}
          icon={<Explore />}
        >
          <Box style={text.h4}>Explore and find valuable investments</Box>

          <p>
            We have a dedicated team of local Real Estate experts who are
            actively on the hunt for finding good investment opportunities. We
            also have connections with various Real Estate agencies which
            greatly help us in finding and closing good deals.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: blue[500], color: "#fff" }}
          icon={<FilterList />}
        >
          <Box style={text.h4}>Extract the best</Box>

          <p>
            We carefully perform negotiations based on market value with each
            and every owner of the properties we think would be a good
            investment and then decide the top two based on price quote and
            market demand.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: blue[500], color: "#fff" }}
          contentArrowStyle={{ borderRight: `7px solid  ${blue[500]}` }}
          iconStyle={{ background: "#fff", color: blue[500] }}
          icon={<AssignmentTurnedIn />}
        >
          <Box style={text.h4}>Due Diligence</Box>
          <p>
            We conduct various kinds of Due diligence before closing a deal
            these include:
            <ul>
              <li>Legal opinion</li>
              <li>Water analysis</li>
              <li>Sand tests</li>
            </ul>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: blue[500], color: "#fff" }}
          icon={<AccountBalance />}
        >
          <Box style={text.h4}>Incorporation</Box>
          <p>
            Once we finalise on a property, we incorporte a LLP company under
            the purview of Ministry of Corporte Affairs which has a dedicated
            bank current account linked to it and all the transactions happen
            with the consent of the partners.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: blue[500], color: "#fff" }}
          contentArrowStyle={{ borderRight: `7px solid  ${blue[500]}` }}
          iconStyle={{ background: "#fff", color: blue[500] }}
          icon={<People />}
        >
          <Box style={text.h4}>Investors</Box>
          <p>
            After incorportation we open the property for investors. All the
            interested investors can invest in the property by buying shares in
            the incorported LLP and become a partner. This is the step where we
            present the opportunity to each and every interested investor and
            decide on a common exit plan.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          iconStyle={{ background: blue[500], color: "#fff" }}
          icon={<BusinessCenter />}
        >
          <Box style={text.h4}>Marketing</Box>
          <p>
            We take the full responsibility of marketing and selling the
            property which will be inline with the predefined exit strategy or
            in some cases on a common consensus between all the investors. In
            some cases, We also give the option to the investors to sell his
            part of the share and exit before the pre determited date of exit.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: blue[500], color: "#fff" }}
          contentArrowStyle={{ borderRight: `7px solid  ${blue[500]}` }}
          iconStyle={{ background: "#fff", color: blue[500] }}
          icon={<Share />}
        >
          <Box style={text.h4}>Sharing profits</Box>

          <p>
            Once we successfully sell the property we share the profits between
            all investors in proportional to their share. We don't impose any
            hidden charges, we just charge 3% on exit only if the the profit for
            each share is more than 12% after deducting income tax. We also
            offer fee waiver if you are going to be a investor on our next
            investment.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<EmojiEmotions />}
        >
          <Box style={text.subtitle0}>
            Enjoy the fruits of your investment !!!
          </Box>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </Box>
  );
};
