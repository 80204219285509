import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { text } from "../controls/text";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: "white",
    color: "black"
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  }
}));

export const MainHeader = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            PropertyWallet
          </Typography>
          <a href="#our-magic" style={{ ...text.link, fontSize: "17px" }}>
            How it works
          </a>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    </>
  );
};
